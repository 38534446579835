import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { rootReducer } from "./redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { OrientationChangeProvider } from "./components/common/layouts/OrientationChangeProvider";
import { CookiesProvider } from "react-cookie";
import RouterComponent from "./RouterComponent";
import * as serviceWorker from "./serviceWorker";

// css
import "./index.css";
import { StrictMode } from "react";

const store = createStore(rootReducer);

// routes
const routing = (
  <>
    <CookiesProvider>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <OrientationChangeProvider>
            <Router>
              <RouterComponent />
            </Router>
          </OrientationChangeProvider>
        </Provider>
      </I18nextProvider>
    </CookiesProvider>
  </>
);

// rendering using the old "React17" way
ReactDOM.render(routing, document.getElementById("root"));

// TODO rendering using the new "React18" way
// the new way currently does not work, probably due to changes regarding state and data batching
// const container = document.getElementById("root");
// const root = createRoot(container!);
// root.render(<StrictMode>{routing}</StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
